/* Base styles */
.terms {
    background-color: white;
    color: black;
    font-family: Arial, sans-serif;
    margin-top: 200px;
    margin-bottom: 50px;
}
.terms h2, .terms h3, .terms h4, .terms a{
    color: black;
}

/* Responsive padding adjustments using media queries */

/* For tablets (portrait) */
@media (min-width: 600px) {
    .terms {
        padding: 20px;
    }
}

/* For tablets (landscape) and smaller desktops/laptops */
@media (min-width: 900px) {
    .terms {
        padding: 30px;
    }
}

/* For larger desktops */
@media (min-width: 1200px) {
    .terms {
        padding: 50px;
    }
}

ol {
    counter-reset: my-counter;
}

ol > li {
    list-style-type: none;
    position: relative;
    padding-left: 2.0rem;
    counter-increment: my-counter;
    font-weight: 800;
}

ol > li:before {
    content: counter(my-counter) ". ";
    position: absolute;
    left: 0;
}

/* Nested list */
ol > li ol {
    counter-reset: nested-counter;
    padding-left: 0;
}

ol > li ol > li {
    list-style-type: none;
    position: relative;
    padding-left: 1.5rem;
    counter-increment: nested-counter;
}

ol > li ol > li:before {
    content: counter(nested-counter, lower-alpha) ". ";
    position: absolute;
    left: 0;
}

ol > li ol > li {
    font-size: normal;
    font-weight: normal;
}

/* extra css for popup */

  
  .popup-content {
    position: fixed;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%); /* This will center the popup */
    width: 100vw; /* full screen width on small screens */
    height: 80vh; /* height as given */
    background-color: rgb(250,101,0); /* assuming a white popup, you can change as needed */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* a subtle shadow, can be omitted if not desired */
    overflow: hidden; /* in case the content inside exceeds, it will be clipped */
    border-radius: 8px; /* Optional: If you want rounded corners */
    z-index: 1000; /* ensuring the popup stays on top */

  }
  /* Media query for larger screens */
@media (min-width: 568px) { /* Assuming the breakpoint is at 768px for large screens */
  .popup-content {
      width: 50vw;
  }
}

  
  .popup-close {
    position: absolute;
    right: 20px;
    top: 20px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    font-weight: 900;
    z-index: 100;
  }
  
  /* Headline */
  
  
  .popup-headline {
    position: absolute;
    top: 0;
    font-size: 30px;
    padding: 40px 15px 0 15px;
    font-weight: 800;
    /* margin-bottom: 15px; */
    line-height: 1.2;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    background: -webkit-linear-gradient(45deg, #fff, #ffedc2);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    transform: rotate(-3deg);
  }
  .contrasting-text {
    background: linear-gradient(45deg, #FA0018, darkred); /* Adjust the colors as needed */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: larger;
  }
  @keyframes slideFadeIn {
    from {
        transform: translateY(-30px);
        transform: translatex(-100px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        transform: translateX(0);
        opacity: 1;
    }
  }
  .popup-headline {
    animation: slideFadeIn 0.5s forwards;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px; /* Rounded corners for the highlight */
  }
   .zone button {
    position: relative;
    /* color: var(--color-one); */
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: .5s all ease-in-out;
    &:hover {
      cursor: pointer;
      color: #1F4141;
      animation: none;
      animation-play-state: paused;
    }
  }
  .button-pulse {
    animation: pulse 2s infinite 3s cubic-bezier(0.25, 0, 0, 1);
  }
  /* popup animation lottie */
  .zone {
    /* border-radius: 100%; */
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  
  button {
    border: 0;
    position: relative;
    padding: 0;
    cursor: pointer;
    background: transparent;
    outline: none;
  }
  
  .bodymovin {
    width: 31px; /* 310 */
    height: 17px; /* 170 */
    position: absolute;
    top: -29px;
    right: 4%;
    z-index: 0;
    pointer-events: none;
    transition: transform 0s;
  }
  
  .bodymovin--hidden {
    transform: translateY(30px);
    transition: transform 0.55s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  
  .textpop {
    position: relative;
    padding: 15px 34px;
    color: #fff;
    /* font-size: 16px; */
    font-weight: 600;
    display: inline-flex;
    background: #0095FA;
    border-radius: 15%;
    transition: background 0.2s ease-in-out;
  }
  
  button:hover .textpop {
    background: var(--black-color-opacity-two);
   }
   @keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
  }
  
  .popup-headline {
    animation: pulse 3s infinite;
    position: relative;
    letter-spacing: 2px;  
  }
  .naansoffer {
    color: #111111;
    font-size: 28px;
    animation: pulse 1s infinite;
    font-weight: 800;
    display: inline-block;
  }

.animation-container {
    justify-content: center;    
  }
.animationSize {
    width: 300px; 
    height: 10px; 
  }
.animationSizesmall {
    width: 300px; 
    height: 20px; 
  }
.starsSize {
  width: 300px;
  height: 80px;
}
.shareSize {
  align-items: center;
  width: 300px;
  margin: 0 auto;
}